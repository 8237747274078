<template>
  <div>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'banners',
      resourceName: 'banners',
      singularName: 'banner',
      categories: [],
      searchFields: {
        title: null,
        status: null,
        category_id: null,
      },
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    statuses() {
      return [
        {
          id: 'active',
          name: this.$t('statuses.active'),
        },
        {
          id: 'inactive',
          name: this.$t('statuses.inactive'),
        },
      ]
    },
  },

}
</script>
